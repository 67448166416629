<template>
  <div>

    <!-- Header-->
    <div class="d-flex justify-space-between">
      <h5 class="wsDARKER">{{ $t('ChooseSegmentation') }} </h5>
      <h5 @click="handleToggleMode"
          class="wsATTENTION linkHover pointer text-right">
        {{ $t('Open')+ ' ' + $t(`mailer.wizard.settings.receivers.edit_mode.${navigation === 'basic' ? 'advanced' : 'basic'}`) }}
      </h5>
    </div>

    <!-- Content-->
    <ws-navigation
        :items="navigationSelect"
        v-model="navigation"
        hide-header
    >
      <template #item.basic>

        <div style="border : 1px solid var(--wsBACKGROUND)" class="wsRoundedLight mb-2">

          <email-receivers-basic-component
              v-for="(item,i) in basicModeSelect" :key="`basic_${i}`"
              @input="handleBasicModeInput"
              v-model="basicData[item.value]"
              :items="item.items"
              :title="item.title"
              :subtitle="item.subtitle"
              :icon="item.icon"
              :single="item.single"
              :is-last=" i === basicModeSelect.length - 1"
              :no-items-text="item.noItemsText"
              :no-search-items-text="item.noSearchItemsText"
          />

        </div>

      </template>

      <template #item.advanced>
        <div >
          <v-sheet
              :color="wsLIGHTCARD"
              class="wsRoundedSemi pa-4 mb-4"
          >
            <emailReceiversGroupFilter
                v-for="(item,i) in filters" :key="i"
                :item="item"
                @add-child="addFilter(i)"
                @delete="deleteFilter(i)"
                @delete-child="deleteChildFilter(i,$event)"
                :conditions-select="filtersConditionsSelect"
                :filters-select="filtersKeysSelect"
                @change="advancedTriggered = true"
            />

            <ws-button @click="addFilter()" block outlined :color="wsACCENT" label="Додати умову"></ws-button>

          </v-sheet>
        </div>
      </template>

    </ws-navigation>

    <div class="d-flex justify-space-between">
      <ws-button
          @click="clearFilters"
          :disabled="!filters.length"
          label="ClearFilters"
          outlined
          :color="wsACCENT"
          class="mr-2"
      />
      <div class="d-flex justify-end">
<!--        <ws-button-->
<!--            @click="cancelChanges"-->
<!--            :disabled="EQ(value,filters)"-->
<!--            label="Cancel"-->
<!--            outlined-->
<!--            :color="wsACCENT"-->
<!--            class="mr-2"-->
<!--        />-->
        <ws-button
            :disabled="!filters.length || EQ(value,filters)"
            @click="saveChanges"
            label="Save"
        />
      </div>
    </div>

    <ws-dialog
        v-if="displayToggleModeDialog"
        v-model="displayToggleModeDialog"
        :title="$t('mailer.wizard.settings.receivers.filters.return_to_basic.title')"
        :save-text="$t('Proceed')"
        @save="returnToBasic"
    >
      <h4 class="wsDARKER font-weight-regular">
        {{ $t('mailer.wizard.settings.receivers.filters.return_to_basic.description') }}
      </h4>
    </ws-dialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import finemailer from "@/modules/finemailer/mixins/finemailer";
import emailReceiversGroupFilter
  from "@modules/finemailer/components/newsletterWizard/emailSettingsAccordion/emailReceiversGroupFilter.vue";
import emailReceiversBasicComponent
  from "@modules/finemailer/components/newsletterWizard/emailSettingsAccordion/emailReceiversBasicComponent.vue";

export default {
  name: "emailReceiversGroupDialog",
  mixins : [finemailer],
  components : {
    emailReceiversGroupFilter,
    emailReceiversBasicComponent
  },
  props : {
    value : {
      type : Array,
      default () { return [] }
    },
    subtract : {
      type : Boolean,
      default: false
    },
    basicFilters : {
      type : Object
    },
  },
  data() {
    return {
      basicData : {
        receiversList : [],
        receiversCourses : [],
        receiversTags : [],
        receiversCourseFilters : null,
      },
      display : false,
      filters : [],
      contactLists : [],
      tags : [],
      courses : [],
      navigation : 'basic',
      listError : false,

      advancedTriggered : false,
      displayToggleModeDialog : false,
    }
  },
  computed : {
    basicModeSelect() {
      return [
        {
          title : 'mailer.wizard.settings.receivers.filters.keys.list.title',
          subtitle : 'mailer.wizard.settings.receivers.filters.keys.list.placeholder',
          items : this.listsFiltered,
          value : 'receiversList',
          icon : 'mdi-tooltip-account',
          noItemsText : "mailer.wizard.settings.receivers.filters.keys.list.no_items_text",
          noSearchItemsText : "mailer.wizard.settings.receivers.filters.keys.list.no_search_items_text"
        },
        {
          title : 'mailer.wizard.settings.receivers.filters.keys.tag.title',
          subtitle : 'mailer.wizard.settings.receivers.filters.keys.tag.placeholder',
          items : this.tagsFiltered,
          value : 'receiversTags',
          icon : 'mdi-label',
          noItemsText : "mailer.wizard.settings.receivers.filters.keys.tag.no_items_text",
          noSearchItemsText : "mailer.wizard.settings.receivers.filters.keys.tag.no_search_items_text"
        },
        {
          title : 'mailer.wizard.settings.receivers.filters.keys.course.title',
          subtitle : 'mailer.wizard.settings.receivers.filters.keys.course.placeholder',
          items : this.courses,
          value : 'receiversCourses',
          icon : 'mdi-school',
          noItemsText : "mailer.wizard.settings.receivers.filters.keys.course.no_items_text",
          noSearchItemsText : "mailer.wizard.settings.receivers.filters.keys.course.no_search_items_text"
        },
        {
          title : 'mailer.wizard.settings.receivers.filters.keys.course_filter.title',
          subtitle : 'mailer.wizard.settings.receivers.filters.keys.course_filter.placeholder',
          items : this.courseFiltersSelect,
          value : 'receiversCourseFilters',
          icon : 'mdi-widgets',
          single:true
        }
      ]
    } ,

    navigationSelect() {
      return [
        { text : this.$t('mailer.wizard.settings.receivers.edit_mode.basic') , value : 'basic' },
        { text : this.$t('mailer.wizard.settings.receivers.edit_mode.advanced')   , value : 'advanced'   }
      ]
    },

    // Selector Items
    filtersKeysSelect() {
      let items = [
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.user_role.title') ,
          value : 'UserRole' ,
          type : 'select' ,
          items : this.userTypesSelect
        },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.list.advanced_title') ,
          value : 'List' ,
          type : 'select' ,
          items : this.listsFiltered
        },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.tag.advanced_title') ,
          value : 'Tag' ,
          type : 'select' ,
          items : this.tagsFiltered
        },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.course.advanced_title') ,
          value : 'Course' ,
          type : 'select' ,
          items : this.courses
        },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.course_filter.advanced_title') ,
          value : 'CourseFilter' ,
          type : 'select' ,
          items : this.courseFiltersSelect
        }
      ]

      return items
    },

    listsFiltered() {
      let items = this.COPY(this.contactLists)
      items = items.filter(el => !el.is_system)
      return items
    },

    tagsFiltered() {
      let items = this.COPY(this.tags)
      items = items.filter(el => !el.is_system)
      return items
    },

    userTypesSelect() {
      return [
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.user_role.values.all') , value : 'all' },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.user_role.values.students') , value : 'students' },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.user_role.values.managers') , value : 'managers' },
      ]
    },

    courseFiltersSelect() {
      return [
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.course_filter.values.payed') , value : 'payed' },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.course_filter.values.has_certificate') , value : 'has_certificate' },
        { text : this.$t('mailer.wizard.settings.receivers.filters.keys.course_filter.values.is_completed') , value : 'is_completed' }
      ]
    },

    // Advanced Selectors
    filtersConditionsSelect() {

      let items = [
        { text : this.$t('mailer.wizard.settings.receivers.filters.condition.equals') , value : '=' },
        { text : this.$t('mailer.wizard.settings.receivers.filters.condition.not_equals') , value : '!=' },
        // { text : this.$t('mailer.wizard.settings.receivers.filters.condition.greater') , value : '>' },
        // { text : this.$t('mailer.wizard.settings.receivers.filters.condition.less') , value : '<' },
        // { text : this.$t('mailer.wizard.settings.receivers.filters.condition.greater_equals') , value : '>=' },
        // { text : this.$t('mailer.wizard.settings.receivers.filters.condition.less_equals') , value : '<=' }
      ]

      return items
    },

  },
  watch : {
    value : {
      handler() {
        if ( !this.EQ(this.filters , this.value)) {
          this.filters = this.COPY(this.value)
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_NEWSLETTER',
      'GET_NEWSLETTER',
      'ADD_RECEIVERS_GROUP',
      'DELETE_RECEIVERS_GROUP'
    ]),
    ...mapActions('contacts' , [
      'GET_CONTACT_LISTS',
      'GET_CONTACT_TAGS'
    ]),

    getModel(item) {
      return {
        get: () => this[item],
        set: value => {
          this[item] = value;
        }
      };
    },

    handleToggleMode() {
      if ( this.navigation === 'advanced' && this.advancedTriggered) {
        this.displayToggleModeDialog = true
        return
      }
      this.navigation = this.navigation === 'advanced' ? 'basic' : 'advanced'
    },

    // Button Actions
    saveChanges() {
      if (!this.validateData()) {
        return
      }

      let data = {
        ...this.basicData,
        advancedTriggered : this.advancedTriggered
      }
      this.$emit('update-basic' , this.COPY(data))
      this.$emit('input' , this.COPY(this.filters))
    },

    clearFilters() {
      this.flushData(true)
      this.$emit('update-basic' , null)
      this.$emit('input' , [])
    },

    cancelChanges() {
      this.filters = this.COPY(this.value)
      this.flushData(true)
      this.navigation = 'basic'
      this.prepareBasicFilters()
    },
    
    returnToBasic() {
      this.navigation = 'basic'
      this.clearFilters()
      this.displayToggleModeDialog = false
    },

    // Basic Mode Functions
    handleBasicModeInput() {
      this.filters = []
      let filtersModel = [
        { items : this.COPY(this.basicData.receiversUserRole) , value : 'UserRole', multiple : false },
        { items : this.COPY(this.basicData.receiversList) , value : 'List', multiple : true },
        { items : this.COPY(this.basicData.receiversTags) , value : 'Tag', multiple : true },
        { items : this.COPY(this.basicData.receiversCourses) , value : 'Course', multiple : true },
        { items : this.COPY(this.basicData.receiversCourseFilters) , value : 'CourseFilter', multiple : false }
      ]
      filtersModel.forEach(filter => {

        if (filter.multiple && filter.items.length) {
          let root = {
            filter : filter.value ,
            condition : '=' ,
            value : filter.items[0] ,
            children : []
          }
          filter.items.forEach((orFilter,i) => {
            if (i>0) { root.children.push({ filter : filter.value, condition : '=' , value : orFilter , }) }
          })
          this.filters.push(root)

        } else if (!filter.multiple && filter.items) {
          this.filters.push({
            filter : filter.value ,
            condition : '=' ,
            value : filter.items ,
            children : []
          })
        }

      })

      this.filters = this.COPY(this.filters)

    },

    prepareBasicFilters() {
      this.basicData =  this.COPY(this.basicFilters)
      if (this.advancedTriggered) {
        this.navigation = 'advanced'
      }
    },

    // Advanced Mode Functions

    addFilter(index = null) {

      this.advancedTriggered = true
      if (index !== null) {
        this.filters[index].children.push(
            { filter : this.filters[index].filter ,
              condition : null ,
              value : null ,
            },
        )

        return
      }
      this.filters.push(
          { filter : null ,
            condition : null ,
            value : null ,
            children : []
          },
      )
    },

    deleteFilter(index) {
      this.filters.splice(index , 1)
    },
    deleteChildFilter(index , childIndex) {
      this.filters[index].children.splice(childIndex , 1)
      this.filters = this.COPY(this.filters)
    },

    //Technical
    validateData() {
      let check = true
      this.filters.forEach(filter => {
        if (!filter.filter || !filter.value || !filter.condition ) {
          check = false
        }
        if (filter.children) {
          filter.children.forEach(child => {
            if (!child.filter || !child.value || !child.condition ) {
              check = false
            }
          })
        }

      })
      if (!check) {
        this.notify(this.$t('mailer.wizard.settings.receivers.filters.validation_error') , 'warning')
      }
      return check
    },

    flushData(force = false , noFilters = false) {
      if (!this.advancedTriggered && !force) {
        return
      }
      if (!noFilters) {
        this.filters = []
        this.filters = this.COPY(this.filters)
      }
      this.basicData.receiversUserRole = null
      this.basicData.receiversList = []
      this.basicData.receiversTags = []
      this.basicData.receiversCourses = []
      this.basicData.receiversCourseFilters = null
      this.advancedTriggered = false
    },

  },
  async mounted() {

    let result = await this.GET_CONTACT_LISTS()
    if (!result) {
      return
    }
    this.contactLists = result.lists
    this.courses = result.courses
    this.tags = result.tags

    if ( this.value ) {
      this.filters = this.COPY(this.value)
    }

    if (this.basicFilters) {
      this.prepareBasicFilters()
    }

  }
}
</script>

<style scoped>
</style>