<template>
  <div>
    <div  class="d-flex align-center flex-row mb-3">
      <ws-select
          v-if="!isChild"
          @input="handleTypeChange"
          v-model="item.filter"
          :items="filtersSelect"
          :placeholder="$t('Filter')"
          class="mr-4"
          width="175px"
          :background-color="wsWHITE"
          hide-details
      />
      <div v-if="isChild" style="width: 175px"   class="mr-4">
        <h5 class="wsACCENT text-center font-weight-regular"> {{ $t('Or') }} </h5>
      </div>
      <ws-select
          v-model="item.condition"
          @input="$emit('change')"
          :items="conditionsSelect"
          :placeholder="$t('mailer.wizard.settings.receivers.filters.condition.title')"
          class="mr-4"
          width="155px"
          :background-color="wsWHITE"
          hide-details
      />
      <ws-select
          v-if="getFilter(item.filter).type === 'select' && getFilter(item.filter).items"
          @input="$emit('change')"
          v-model="item.value"
          :items="getFilter(item.filter).items"
          :placeholder="$t('mailer.wizard.settings.receivers.filters.value')"
          :background-color="wsWHITE"
          width="155px"
          class="mr-4"
          :autocomplete="getFilter(item.filter).items.length > 7"
          hide-details
      >
      </ws-select>
      <ws-text-field
          v-else
          @input="$emit('change')"
          v-model="item.value"
          class="mr-4"
          :placeholder="$t('mailer.wizard.settings.receivers.filters.value')"
          :background-color="wsWHITE"
          width="155px"
      />

      <div style="width: 38px"
      >
        <v-btn v-if="isLast || (!(item.children && item.children.length) && !isChild)"
            @click="$emit('add-child');$emit('change')" icon>
          <v-icon :color="wsDARKLIGHT">mdi-plus</v-icon>
        </v-btn>
      </div>

      <v-btn icon>
        <v-icon   @click="$emit('delete');$emit('change')" :color="wsDARKLIGHT">mdi-delete-outline</v-icon>
      </v-btn>
    </div>

    <div v-if="!isChild && item.children && item.children.length">
      <email-receivers-group-filter
          v-for="(child, i) in item.children" :key="i"
          @add-child="$emit('add-child')"
          @change="$emit('change')"
          @delete="deleteChild(i)"
          :item="child"
          :filters-select="filtersSelect"
          :conditions-select="conditionsSelect"
          :is-last="i === item.children.length - 1"
          is-child
      />
    </div>


  </div>
</template>

<script>
export default {
  name: "emailReceiversGroupFilter",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    filtersSelect : {
      type : Array,
      default() { return [] }
    },
    conditionsSelect : {
      type : Array,
      default() { return [] }
    },
    isChild : {
      type : Boolean,
      default: false
    },
    isLast : {
      type : Boolean,
      default: false
    }
  },
  methods : {
    handleTypeChange () {
      this.item.condition = null
      this.item.value = null
      this.item.children = []
      this.$emit('change')
    },
    deleteChild(index) {
      this.$emit('delete-child', index);
    },
    getFilter(value) {
      return this.filtersSelect.find(el=>el.value === value) || {}
    },
  }
}
</script>



<style scoped>

</style>